<template>
    <SectionWrapper
        :action-button-options="actionButtonOptions"
        @action="isActive = true">
        <template #sticky>
            <span></span>
        </template>
        <RolesTable class="mt-5"></RolesTable>

        <RolesNewRoleModal></RolesNewRoleModal>
    </SectionWrapper>
</template>

<script>
  import RolesTable from "@/components/Admin/Roles/RolesTable";
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_MODAL } from "@core/store/mutation-constants";
  import RolesNewRoleModal from "@/components/Admin/Roles/RolesNewRoleModal.vue";
  import { mapActions } from "vuex";
  import { SET_EMPTY } from "@core/store/action-constants";

  export default {
    name: "Roles",
    components: {
      RolesNewRoleModal,
      RolesTable,
      SectionWrapper
    },

    computed: {
      ...mapFields("admin/roles/rolesModal", {
        fields: ["isActive"],
        base: "option",
        action: UPDATE_MODAL
      }),

      actionButtonOptions () {
        return {
          label: this.$t("admin.roles.tooltip.addRoles"),
          permissions: ["ROLE.CREATE"]
        };
      }
    },

    methods: {
      ...mapActions("admin/roles", {
        SET_EMPTY
      })
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>