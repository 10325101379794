<template>
    <Roles></Roles>
</template>

<script>
  import Roles from "@/components/Admin/Roles/Roles";
  
  export default {
    name: "RolesView",
    components: { Roles }
  };
</script>

<style scoped>

</style>