<template>
    <TablePlaceholder :data="items">
        <template #default="{ items: _items }">
            <TilesTable
                :columns="4"
                :loading="isLoading"
                :items="_items">
                <template #default="{ item }">
                    <RolesTableItem
                        :item="item"
                        :loading="roleId === item.id "
                        @delete="deleteRole($event)"
                        @edit="selectRole($event)">
                        <template #content>
                            &#8203;
                        </template>
                    </RolesTableItem>
                </template>
            </TilesTable>
        </template>
        <template #placeholder>
            {{ $t(`common.table.noData`) }}
        </template>
    </TablePlaceholder>
</template>

<script>
  import RolesTableItem from "@/components/Admin/Roles/RolesTableItem";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import TilesTable from "@/components/Common/TilesTable";
  import { DELETE_ROLE, GET_ROLE_BY_ID, GET_ROLES } from "@core/store/action-constants";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapState } from "vuex";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { UPDATE_MODAL, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  
  export default {
    name: "RolesTable",
    components: {
      RolesTableItem,
      TilesTable,
      TablePlaceholder
    },
    created () {
      this.GET_ROLES();
    },
    data () {
      return {
        roleId: null,
        editRoleModal: false
      };
    },
    computed: {
      ...mapState("admin/roles", {
        roles: state => _cloneDeep(state.roles)
      }),

      ...mapFields("admin/roles/rolesModal", {
        fields: ["isActive"],
        base: "option",
        action: UPDATE_MODAL
      }),

      items () {
        const items = _cloneDeep(this.roles);

        if (items && items.items) {
          items.items = items.items.map(role => {
            return {
              id: role.id,
              title: role.name,
              content: role.permissions
            };
          });
        }

        return items;
      },

      isLoading () {
        return this.$wait(`admin/roles/${ GET_ROLES }`);
      }
    },
    methods: {
      ...mapActions("admin/roles", {
        GET_ROLES,
        DELETE_ROLE,
        UPDATE_MODAL_ACTIVE: `rolesModal/${ UPDATE_MODAL_ACTIVE }`,
        GET_ROLE_BY_ID: `rolesModal/${ GET_ROLE_BY_ID }`
      }),

      deleteRole (roleId) {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.role.action.delete.title"),
          message: this.$t("common.entity.role.action.delete.confirm"),
          confirmText: this.$t("common.buttons.delete"),
          cancelText: this.$t("common.buttons.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            try {
              this.roleId = roleId;
              await actionWithToast(this.DELETE_ROLE(roleId), "common.entity.role.action.delete.messages");
            } finally {
              this.roleId = null;
            }
          }
        });
      },

      selectRole ({ id }) {
        this.isActive = true;
        this.GET_ROLE_BY_ID(id);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .is-ancestor {
        margin-top: -1.5em;
    }

    .permissions {
        list-style: none;

        .permission {}
    }
</style>
