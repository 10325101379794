<template>
    <ModalBox
        :is-active.sync="isActive"
        :is-loading="isLoading.roleById"
        @after-leave="closeModal"
        @after-enter="afterEnter"
        @submit="updateRole">
        <template #header>
            <h5 class="title is-5">
                {{ isEdit ? $t("common.entity.role.action.edit.title") : $t("common.entity.role.action.create.title") }}
            </h5>
        </template>
        <b-field>
            <LInput
                v-model.trim="name"
                :placeholder="$t('common.entity.role.action.create.labels.roleName')"
                required></LInput>
        </b-field>
        <div class="column px-0">
            <div class="columns is-multiline">
                <div class="column">
                    <CheckGroup
                        :key="key"
                        v-for="(permission, key) in groupedPermissions"
                        v-model="selectedPermissions[key]"
                        check-all
                        :title="groupTitle(key)"
                        :check-list="permission">
                        <template #check="{ check, value, update }">
                            <CustomCheckbox
                                :value="value"
                                class="is-small"
                                @input="update(check)">
                                <template #label>
                                    {{ getPermissionsLabel(check.label) }}
                                </template>
                            </CustomCheckbox>
                        </template>
                    </CheckGroup>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <b-button
                            class="is-fullwidth"
                            native-type="submit"
                            type="is-info is-light"
                            :loading="isLoading.roleAdding || isLoading.roleUpdate">
                            {{ $t("common.buttons.save") }}
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            class="is-fullwidth"
                            type="is-light-button"
                            :loading="isLoading.roleAdding || isLoading.roleUpdate"
                            @click="closeModal">
                            {{ $t("common.buttons.cancel") }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import CheckGroup from "@/components/Common/CheckGroup";
  import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import {
    ADD_ROLE,
    GET_PERMISSIONS,
    UPDATE_ROLES_FORM_MODAL,
    UPDATE_ROLE,
    SET_EMPTY,
    GET_ROLE_BY_ID
  } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import _groupBy from "lodash/groupBy";
  import { UPDATE_MODAL } from "@core/store/mutation-constants";

  export default {
    name: "RolesNewRoleModal",
    components: {
      CustomCheckbox,
      CheckGroup,
      ModalBox
    },

    data () {
      return {
        selectedPermissions: []
      };
    },

    computed: {
      ...mapState("admin/roles", {
        permissionsList: ({ permissions }) => permissions ?? []
      }),

      ...mapFields("admin/roles/rolesModal", {
        fields: ["isActive"],
        base: "option",
        action: UPDATE_MODAL
      }),

      ...mapFields("admin/roles/rolesModal", {
        fields: ["id", "name", "permissions"],
        base: "form",
        action: UPDATE_ROLES_FORM_MODAL
      }),

      groupedPermissions () {
        return _groupBy(this.preparePermissions(this.permissionsList),
                        group => group?.label?.match(/\w+/));
      },

      isEdit () {
        return this.id !== null;
      },

      isLoading () {
        return {
          roleAdding: this.$wait(`admin/roles/rolesModal/${ ADD_ROLE }`),
          roleUpdate: this.$wait(`admin/roles/rolesModal/${ UPDATE_ROLE }`),
          roleById: this.$wait(`admin/roles/rolesModal/${ GET_ROLE_BY_ID }`) ||
            this.$wait(`admin/roles/${ GET_PERMISSIONS }`)
        };
      }
    },

    methods: {
      ...mapActions("admin/roles", {
        UPDATE_ROLES_FORM_MODAL: `rolesModal/${ UPDATE_ROLES_FORM_MODAL }`,
        SET_EMPTY_MODAL:`rolesModal/${ SET_EMPTY }`,
        UPDATE_ROLE: `rolesModal/${ UPDATE_ROLE }`,
        ADD_ROLE:`rolesModal/${ ADD_ROLE }`,
        GET_PERMISSIONS
      }),

      closeModal () {
        this.SET_EMPTY_MODAL();
        this.selectedPermissions = [];
      },

      afterEnter () {
        if (!this.permissionsList.length) {
          this.GET_PERMISSIONS();
        }
      },

      groupTitle (key) {
        const namespace = `common.permissions.groupTitle.${ key }`;
        return this.$te(namespace) ? this.$t(namespace) : key.replace(/_/g, " ");
      },

      getPermissionsLabel (name) {
        // * Заменяем точку на символ нижнего подчеркивания
        const key = `common.permissions.${ name.replace(/\./g, "_") }`;
        return this.$te(key) ? this.$t(key).toUpperCase() : name;
      },

      preparePermissions (permissions) {
        return permissions.map(permission => ({ label: permission, value: permission }));
      },

      async updateRole () {
        const permissions = Object.values(this.selectedPermissions)
          .reduce((acc, arr) => acc.concat(arr))
          .map(({ value }) => value);

        await actionWithToast(
          this.isEdit ? await this.UPDATE_ROLE(permissions) : await this.ADD_ROLE(permissions),
          `common.entity.role.action.${ this.isEdit ? "edit": "create" }.messages`);
        this.closeModal();
      }
    },

    watch: {
      "permissions.length" () {
        this.selectedPermissions = _groupBy(this.preparePermissions(this.permissions),
                                            group => group?.label?.match(/\w+/));
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .modal-card {
      max-width: 800px;
      width: 100vw;
    }
  }

  .row-grid {
    &--checkbox-cover {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
  }
</style>
